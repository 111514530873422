import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, LinearTickOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color } from 'ng2-charts';
import * as _ from 'lodash';

import { CarrierChartService } from 'src/app/services/carrier-chart.service';
import { IChart } from 'src/app/models/chart.model';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CorrectiveActionChartModalComponent } from '../corrective-action-chart-modal/corrective-action-chart-modal.component';

@Component({
  selector: 'app-corrective-action-chart',
  templateUrl: './corrective-action-chart.component.html',
  styleUrls: ['./corrective-action-chart.component.scss']
})
export class CorrectiveActionChartComponent implements OnInit {
  @Input() chartDetails;
  @Input() hideModalLinkFlag;
  public chartModalRef: NgbModalRef;
  public showModalLink = false;

  public chartData: IChart;
  public barChartLabels: string[];
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: Chart.ChartDataSets[];
  public barChartColor: Color[];
  public tickOptions: LinearTickOptions = {
    beginAtZero: true,
    precision: 0,
    maxTicksLimit: 5
  };
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    title: {
      display: true,
      text: '',
      padding: 25
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 12,
          callback: (value) => {
            // Update xAxes lable string
            return this.carrierChartService.setCorrectiveActionLabel(value);
          }
        }
      }], yAxes: [{
        ticks: this.tickOptions,
        scaleLabel: {
          display: true,
          labelString: '',
          fontSize: 10
        },
        gridLines: {
          offsetGridLines: false
        }
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        display: true,
        font: {
          size: 12
        }
      }
    }
  };
  constructor(private carrierChartService: CarrierChartService, private modalService: NgbModal) {

  }

  ngOnInit(): void {
    if (this.chartDetails) {
      this.chartData = _.cloneDeep(this.chartDetails);
      // Update chart data
      this.barChartOptions.title.text = this.chartData.title;
      this.barChartLabels = this.chartData.chartLabel;
      this.barChartData = this.chartData.chartDataSet;
      this.barChartColor = this.chartData.chartColors;


      let datalength;
      _.forEach(this.chartData.chartDataSet, value => {
        // Update Bar formatting
        value.maxBarThickness = 50;
        value.barPercentage = 0.5;
        value.barThickness = 'flex';

        datalength = value.data.length;

        if (datalength > 10) {
          this.barChartLabels = this.chartData.chartLabel.slice(0, 10);
          // Set first 10 records
          const first10Records = value.data.slice(0, 10);
          value.data = first10Records;
          this.showModalLink = true;
        } else {
          this.barChartLabels = this.chartData.chartLabel;
          this.showModalLink = false;
        }
      });

      // Iterate yAxes option array and set scale label
      _.forEach(this.barChartOptions.scales.yAxes, value => {
        if (value.scaleLabel) {
          value.scaleLabel.labelString = this.chartData.yaxisScaleLabel;
        }
      });
      this.barChartData = this.chartData.chartDataSet;
    }
  }


  public showChartSummryView() {
    this.chartModalRef = this.modalService.open(CorrectiveActionChartModalComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true
    });
    this.chartModalRef.componentInstance.data = this.chartDetails;
  }
}
