import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpCustomInterceptorService implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // We retrieve the token, if any
    let newHeaders = req.headers;

    const authToken = req.headers.get('Authorization');
    if (authToken) {
      newHeaders = newHeaders.append('X-Authorization', authToken);
    }

    const authReq = req.clone({ headers: newHeaders });
    return next.handle(authReq);
  }
}
