import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-metric-display',
  templateUrl: './metric-display.component.html',
  styleUrls: ['./metric-display.component.scss'],
})
export class MetricDisplayComponent implements OnInit {
  @Input() value: string | number;
  @Input() label: string;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';

  constructor() { }

  ngOnInit() { }
}
