<div *ngIf="chartData; else message">
        <span class="link"
              *ngIf="hideModalLinkFlag ? !hideModalLinkFlag : showModalLink"
              (click)="showChartSummryView()">Click to view full chart</span>
        <canvas baseChart
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [chartType]="barChartType"
                [colors]="barChartColor"
                height="175">
        </canvas>
</div>
<ng-template #message>No data available</ng-template>