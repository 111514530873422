<div *ngIf="chartData; else message">
        <canvas baseChart
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [legend]="true"
                [chartType]="lineChartType"
                [colors]="lineChartColors"
                height="175">
        </canvas>
</div>
<ng-template #message>No data available</ng-template>