<div *ngIf="chartData; else message">
    <canvas baseChart
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [legend]="false"
            [chartType]="barChartType"
            [colors]="barChartColor"
            height="175">
    </canvas>

    <ul *ngIf="barChartData"
        class="legend d-flex justify-content-center">
        <li *ngFor="let legend of barChartData; let i=index"
            class="mx-2">
            <span class="m-1"
                  *ngIf="i !== (barChartData.length - 1)"
                  [ngStyle]="{'background-color': legend.backgroundColor, 'border-color': legend.borderColor, 'border': 2+'px solid '+legend.borderColor}">
            </span>
            <span class="m-2"
                  *ngIf="i === (barChartData.length - 1)"
                  [ngStyle]="{'height': 1+'px', 'background-color': white, 'border-color': legend.borderColor, 'border-bottom': 2+'px dashed '+ legend.borderColor}"></span>
            {{legend.label}}
        </li>
    </ul>
</div>
<ng-template #message>No data available</ng-template>