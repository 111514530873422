import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import {
  AuthCallbackComponent,
  AuthenticationGuard,
  AuthorizationGuard,
} from '@com-delta-dcflightreliability/dc-common';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'reliability-index'
  },
  {
    canLoad: [AuthenticationGuard],
    canActivate: [AuthorizationGuard],
    data: {
      roles: [
        'FOMDCCFREL_DccPrdReliabilityAdmin',
        'FOMDCCFREL_DccPrdPortalAdmin',
        'FOMDCCFREL_UrDccPrdReliabilityIndexAdmin',
        'FOMDCCFREL_UrDccPrdReliabilityIndexAnalyst',
        'FOMDCCFREL_DccPrdPinnacleOccStaff',
        'FOMDCCFREL_DccPrdSkywestOccStaff',
        'FOMDCCFREL_DccPrdRepublicAirlinesOccStaff',
        'FOMDCCFREL_DccPrdGoJetOccStaff',
        'FOMDCCFREL_DccPrdCompassOccStaff'
      ],
    },
    path: 'reliability-index',
    loadChildren: () =>
      import('./views/reliability-index/reliability-index.module').then(
        (m) => m.ReliabilityIndexModule
      )
  },
  {
    canLoad: [AuthenticationGuard],
    canActivate: [AuthorizationGuard],
    data: {
      roles: [
        'FOMDCCFREL_DccPrdReliabilityAdmin',
        'FOMDCCFREL_DccPrdPortalAdmin',
        'FOMDCCFREL_UrDccPrdReliabilityIndexAdmin',
        'FOMDCCFREL_UrDccPrdReliabilityIndexAnalyst',
        'FOMDCCFREL_DccPrdPinnacleOccStaff',
        'FOMDCCFREL_DccPrdSkywestOccStaff',
        'FOMDCCFREL_DccPrdRepublicAirlinesOccStaff',
        'FOMDCCFREL_DccPrdGoJetOccStaff',
        'FOMDCCFREL_DccPrdCompassOccStaff'
      ],    },
    path: 'odi-index',
    loadChildren: () =>
      import('./views/odi-index/odi-index.module').then(
        (m) => m.ODIIndexModule
      )
  },

  {
    canLoad: [AuthenticationGuard],
    canActivate: [AuthorizationGuard],
    data: {
      roles: ['FOMDCCFREL_UrDccPrdReliabilityIndexAdmin', 'FOMDCCFREL_DccPrdReliabilityAdmin', 'FOMDCCFREL_UrDccPrdReliabilityIndexAnalyst'],
    },
    path: 'admin',
    loadChildren: () =>
      import('./views/reliability-admin/reliability-admin.module').then(
        (m) => m.ReliabilityAdminModule
      )
  },
  {
    path: 'dashboard',
    canActivate: [AuthenticationGuard],
    canLoad: [AuthenticationGuard],
    loadChildren: () =>
      import('./views/reliability-dashboard/reliability-dashboard.module').then(
        (m) => m.ReliabilityDashboardModule
      )
  },
  {
    canLoad: [AuthenticationGuard],
    path: 'carrier-upload',
    loadChildren: () =>
      import('./views/carrier-upload/carrier-upload.module').then(
        (m) => m.CarrierUploadModule
      )
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent
  },
  {
    canLoad: [AuthenticationGuard],
    canActivate: [AuthorizationGuard],
    data: {
      roles: ['FOMDCCFREL_UrDccPrdReliabilityIndexAdmin',
        'FOMDCCFREL_UrDccPrdReliabilityIndexAnalyst',
        'FOMDCCFREL_DccPrdReliabilityAdmin',
        'FOMDCCFREL_DccPrdPinnacleOccStaff',
        'FOMDCCFREL_DccPrdSkywestOccStaff',
        'FOMDCCFREL_DccPrdRepublicAirlinesOccStaff',
        'FOMDCCFREL_DccPrdGoJetOccStaff',
        'FOMDCCFREL_DccPrdCompassOccStaff']
    },
    path: 'reliability-report',
    loadChildren: () =>
      import('./views/reliability-report/reliability-report.module').then(
        (m) => m.ReliabilityReportModule
      )
  },
  {
    path: 'odi-dashboard',
    canActivate: [AuthenticationGuard],
    canLoad: [AuthenticationGuard],
    loadChildren: () =>
      import('./views/odi/odi.module').then(
        (m) => m.OdiModule
      )
  },
  {
    canLoad: [AuthenticationGuard],
    canActivate: [AuthorizationGuard],
    data: {
      roles: ['FOMDCCFREL_UrDccPrdReliabilityIndexAdmin', 'FOMDCCFREL_DccPrdReliabilityAdmin', 'FOMDCCFREL_UrDccPrdReliabilityIndexAnalyst'],
    },
    path: 'flight-utilization',
    loadChildren: () =>
      import('./views/flight-utilization/flight-utilization.module').then(
        (m) => m.FlightUtilizationModule
      )
  },
  {
    canLoad: [AuthenticationGuard],
    canActivate: [AuthorizationGuard],
    data: {
      roles: ['FOMDCCFREL_UrDccPrdReliabilityIndexAdmin',
        'FOMDCCFREL_UrDccPrdReliabilityIndexAnalyst',
        'FOMDCCFREL_DccPrdReliabilityAdmin',
        'FOMDCCFREL_DccPrdPinnacleOccStaff',
        'FOMDCCFREL_DccPrdSkywestOccStaff',
        'FOMDCCFREL_DccPrdRepublicAirlinesOccStaff',
        'FOMDCCFREL_DccPrdGoJetOccStaff',
        'FOMDCCFREL_DccPrdCompassOccStaff']
    },
    path: 'msg-dashboard',
    loadChildren: () =>
    import('./views/msg-dashboard/msg-dashboard.module').then(
      (m) => m.MessageDashboardModule
      )
  },
  {
    canLoad: [AuthenticationGuard],
    canActivate: [AuthorizationGuard],
    data: {
      roles: ['FOMDCCFREL_UrDccPrdReliabilityIndexAdmin',
        'FOMDCCFREL_UrDccPrdReliabilityIndexAnalyst',
        'FOMDCCFREL_DccPrdReliabilityAdmin',
        'FOMDCCFREL_DccPrdPinnacleOccStaff',
        'FOMDCCFREL_DccPrdSkywestOccStaff',
        'FOMDCCFREL_DccPrdRepublicAirlinesOccStaff',
        'FOMDCCFREL_DccPrdGoJetOccStaff',
        'FOMDCCFREL_DccPrdCompassOccStaff']
    },
    path: 'mdc-dashboard',
    loadChildren: () =>
    import('./views/mdc/mdc-dashboard.module').then(
      (m) => m.MDCDashboardModule
      )
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
