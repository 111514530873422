import { Color } from 'ng2-charts';

export interface IChart {
    title: string;
    chartLabel: string[];
    yaxisScaleLabel: string;
    chartDataSet: Chart.ChartDataSets[];
    chartColors: Color[];
    chartcarrierColors: Color[];
    yaxisScaleValues?: [];
}

export enum ChartType {
    comparison = 'CARRIER',
    carrierRate = 'YEARWISE',
    correctiveAction = 'CORRECTIVEACTION',
    station = 'STATION',
    discrepancy = 'DISCREPANCY',
    delayMinutes = 'DELAYMINUTES',
    tails = 'TAILS',
    hazard = 'HAZARD',
    rollingRate = 'ROLLINGRATES',
    odiResults = 'ODIRESULTS',
    odiResultDriver = 'RESULTANDDRIVER',
    odiCode = 'ODICODE',
    odiDriver = 'RESULTDRIVER'
}

export enum GraphName {
    comparison = 'Carrier Comparison',
    carrierRate = 'YOY Rate',
    correctiveAction = 'Corrective Action',
    station = 'Station chart',
    discrepancy = 'Discrepancy',
    delayMinutes = 'Delay minutes'
}
