import { Component, OnInit, Input } from '@angular/core';
import { Color } from 'ng2-charts';
import * as _ from 'lodash';

import { CarrierChartService } from 'src/app/services/carrier-chart.service';
import { IChart } from 'src/app/models/chart.model';

@Component({
  selector: 'app-carrier-rate-chart',
  templateUrl: './carrier-rate-chart.component.html',
  styleUrls: ['./carrier-rate-chart.component.scss']
})
export class CarrierRateChartComponent implements OnInit {
  @Input() description: string;
  @Input() carrier: string;
  @Input() chartDetails;
  public chartData: IChart;
  public lineChartColors: Color[];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartData: Chart.ChartDataSets[];
  public lineChartLabels: string[];
  public lineChartOptions: any = {
    responsive: true,
    title: {
      display: true,
      text: '',
      padding: 25
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        stacked: false,
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5
        },
        scaleLabel: {
          display: true,
          labelString: '',
          fontSize: 10
        },
        gridLines: {
          offsetGridLines: false
        }
      }]
    },
    legend: {
      position: 'bottom',
      display: true
    },
    plugins: {
      datalabels: {
        // hide datalabels for this datasets
        display: false
      }
    },
    elements: {
      point: {
        pointStyle: 'dash'
      }
    }
  };

  constructor(private carrierChartService: CarrierChartService) {
  }

  ngOnInit(): void {
    if (this.chartDetails) {
      this.chartData = this.chartDetails;

      // Update chart data
      this.lineChartOptions.title.text = this.chartData.title + ' - ' + this.description;
      this.lineChartLabels = this.chartData.chartLabel;
      this.lineChartColors = this.chartData.chartColors;

      // Iterate yAxes option array and set scale label
      _.forEach(this.lineChartOptions.scales.yAxes, value => {
        if (value.scaleLabel) {
          value.scaleLabel.labelString = this.chartData.yaxisScaleLabel;
        }
      });

      // Update point and line style
      const currentYearRecord = _.first(this.chartData.chartDataSet);
      currentYearRecord.pointStyle = 'rect';
      const lastYearRecord = _.last(this.chartData.chartDataSet);
      lastYearRecord.borderWidth = 1;

      this.lineChartData = this.chartData.chartDataSet;
    }
  }
}
