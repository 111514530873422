import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment-timezone';

@Pipe({
  name: 'formatDateTime',
})
export class FormatDateTimePipe implements PipeTransform {
  transform(
    value: any,
    format: string,
    timeZone?: string,
    ...args: any[]
  ): any {
    const date = timeZone ? moment.tz(value, timeZone) : moment(value);

    return `${date.format(format)} ${args.join(' ')}`;
  }
}
