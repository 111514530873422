import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, LinearTickOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

import { Color, Label } from 'ng2-charts';
import * as _ from 'lodash';
import { IChart } from 'src/app/models/chart.model';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CarrierChartService } from 'src/app/services/carrier-chart.service';
import { OdiResultDriverModalComponent } from '../odi-result-driver-modal/odi-result-driver-modal.component';

@Component({
  selector: 'app-odi-result-driver',
  templateUrl: './odi-result-driver.component.html',
  styleUrls: ['./odi-result-driver.component.scss']
})
export class OdiResultDriverComponent implements OnInit {
  @Input() chartDetails;
  @Input() hideModalLinkFlag;
  public chartModalRef: NgbModalRef;
  public showModalLink = false;

  public chartData: IChart;
  public barChartLabels: any;
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: Chart.ChartDataSets[];
  public barChartColor: Color[];
  public tickOptions: LinearTickOptions = {
    beginAtZero: true,
    precision: 0,
    maxTicksLimit: 5
  };
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    title: {
      display: true,
      text: '',
      padding: 25
    },
    scales: {
      xAxes: [
        {
          id: 'xAxis1',
          type: 'category',
          // offset: true,
          gridLines: {
            display: false,
            drawOnChartArea: false,
            tickMarkLength: 20
          },
          ticks: {
            padding: -15,
            autoSkip: false,
            maxRotation: 0,
            fontSize: 10,
            callback: (label) => {
              return this.carrierChartService.setCorrectiveActionLabel(label, true);
            }
          }
        },
        {
          id: 'xAxis2',
          type: 'category',
          offset: true,
          gridLines: {
            drawOnChartArea: false,
            offsetGridLines: false,
          },
          ticks: {
            padding: -10,
            maxRotation: 0,
            autoSkip: false,
            fontSize: 10,
            fontStyle: 'bold',
            callback: (label) => {
              return label;
            }
          }
        }
      ],
      yAxes: [{
        ticks: this.tickOptions,
        scaleLabel: {
          display: true,
          labelString: '',
          fontSize: 10
        },
        gridLines: {
          offsetGridLines: false
        }
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        display: true,
        font: {
          size: 12
        }
      }
    }
  };

  constructor(private carrierChartService: CarrierChartService, private modalService: NgbModal) {}

  ngOnInit(): void {
    if (this.chartDetails) {
      this.chartData = _.cloneDeep(this.chartDetails);
      // Update chart data
      const chartLabels = this.carrierChartService.createLabelsArray(this.chartData.chartLabel);
      this.barChartOptions.title.text = this.chartData.title;
      this.barChartLabels = this.carrierChartService.calculateGroupLabels(chartLabels);
      this.barChartData = this.chartData.chartDataSet;
      this.barChartColor = this.chartData.chartColors;

      let datalength;
      _.forEach(this.chartData.chartDataSet, (value) => {
        // Update Bar formatting
        value.maxBarThickness = 50;
        value.barPercentage = 0.5;
        value.barThickness = 'flex';

        datalength = value.data.length;

        if (datalength > 10) {
          this.barChartLabels = chartLabels.slice(0, 10);
          // Set first 10 records
          const first10Records = value.data.slice(0, 10);
          value.data = first10Records;
          this.showModalLink = true;
        } else {
          this.barChartLabels = chartLabels;
          this.showModalLink = false;
        }
      });

      // Iterate yAxes option array and set scale label
      _.forEach(this.barChartOptions.scales.yAxes, value => {
        if (value.scaleLabel) {
          value.scaleLabel.labelString = this.chartData.yaxisScaleLabel;
        }
      });

      // Iterate xAxes option array and set scale label
      _.forEach(this.barChartOptions.scales.xAxes, value => {
        if (value.id === 'xAxis1') {
          value.labels = this.barChartLabels.map((entry => entry[1]));
        } else {
          value.labels = this.carrierChartService.calculateGroupLabels(this.barChartLabels);
        }
        // value.labels = this.carrierChartService.calculateGroupLabels(this.barChartLabels);
      });

      this.barChartData = this.chartData.chartDataSet;
    }
  }

  public showChartSummryView() {
    this.chartModalRef = this.modalService.open(OdiResultDriverModalComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true
    });
    this.chartModalRef.componentInstance.data = this.chartDetails;
  }
}
