<div class="modal-header">
    <h4> Station Chart </h4>
    <button type="button"
            class="close"
            aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body chart-container">

    <div *ngIf="chartData">
        <canvas baseChart
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [chartType]="barChartType"
                [colors]="barChartColor">
        </canvas>
    </div>
</div>