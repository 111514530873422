import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Modules
import {
  NgbDatepickerModule,
  NgbButtonsModule,
  NgbTabsetModule,
  NgbModalModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbTooltipModule,
  NgbPaginationModule,
  NgbCollapseModule,
  NgbNavModule,
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { ChartsModule } from 'ng2-charts';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Chart } from 'chart.js';
import * as HierarchicalScale from 'chartjs-scale-hierarchical';
Chart.plugins.register(HierarchicalScale);

// Services
import {
  NgbDateCustomAdapter,
  NgbDateMomentParserFormatter,
} from 'src/app/services/date-structure.service';

// Components
import { HeaderComponent } from 'src/app/components/header/header.component';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { StatusIndicatorComponent } from 'src/app/components/status-indicator/status-indicator.component';
import { NgbdSortableHeader } from '../../directives/sortable.directive';
import { SortIndicatorComponent } from '../../components/sort-indicator/sort-indicator.component';
import { MetricDisplayComponent } from 'src/app/components/metric-display/metric-display.component';

// Chart components
import { CarrierRateChartComponent } from 'src/app/components/carrier-rate-chart/carrier-rate-chart.component';
// tslint:disable-next-line: max-line-length
import { CarrierComparisonRateChartComponent } from 'src/app/components/carrier-comparison-rate-chart/carrier-comparison-rate-chart.component';
import { CorrectiveActionChartComponent } from 'src/app/components/corrective-action-chart/corrective-action-chart.component';
// tslint:disable-next-line: max-line-length
import { CorrectiveActionChartModalComponent } from 'src/app/components/corrective-action-chart-modal/corrective-action-chart-modal.component';
import { DiscrepancyChartComponent } from 'src/app/components/discrepancy-chart/discrepancy-chart.component';
import { DiscrepancyChartModalComponent } from 'src/app/components/discrepancy-chart-modal/discrepancy-chart-modal.component';
import { StationChartComponent } from 'src/app/components/station-chart/station-chart.component';
import { StationChartModalComponent } from 'src/app/components/station-chart-modal/station-chart-modal.component';
import { DelayMinuteChartComponent } from 'src/app/components/delay-minute-chart/delay-minute-chart.component';
import { HazardChartComponent } from 'src/app/components/hazard-chart/hazard-chart.component';
import { RollingRateComponent } from 'src/app/components/rolling-rate/rolling-rate.component';
import { OdiResultsChartComponent } from 'src/app/components/odi-results-chart/odi-results-chart.component';
import { BarChartModalComponent } from 'src/app/components/bar-chart-modal/bar-chart-modal.component';
import { OdiResultDriverComponent } from 'src/app/components/odi-result-driver/odi-result-driver.component';
import { OdiDriverComponent } from 'src/app/components/odi-driver/odi-driver.component';
import { OdiCodeComponent } from 'src/app/components/odi-code/odi-code.component';
import { OdiResultDriverModalComponent } from 'src/app/components/odi-result-driver-modal/odi-result-driver-modal.component';

import { FormatDateTimePipe } from '../../pipes/format-date-time.pipe';
import { FilterGlobalPipe } from 'src/app/pipes/filter-global.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    StatusIndicatorComponent,
    NgbdSortableHeader,
    SortIndicatorComponent,
    FormatDateTimePipe,
    MetricDisplayComponent,
    CarrierRateChartComponent,
    CarrierComparisonRateChartComponent,
    CorrectiveActionChartComponent,
    CorrectiveActionChartModalComponent,
    DiscrepancyChartComponent,
    DiscrepancyChartModalComponent,
    StationChartComponent,
    StationChartModalComponent,
    DelayMinuteChartComponent,
    FilterGlobalPipe,
    HazardChartComponent,
    RollingRateComponent,
    OdiResultsChartComponent,
    BarChartModalComponent, OdiResultDriverComponent, OdiDriverComponent, OdiCodeComponent,
    OdiResultDriverModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDatepickerModule,
    NgbButtonsModule,
    NgbTabsetModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbPaginationModule,
    MomentModule,
    ChartsModule,
    HttpClientModule,
    NgSelectModule,
    RouterModule,
    InfiniteScrollModule,
    FontAwesomeModule,
    NgbCollapseModule,
    NgbNavModule,
    NgbModule
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateCustomAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateMomentParserFormatter },
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    NgbDatepickerModule,
    NgbButtonsModule,
    NgbTabsetModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbPaginationModule,
    NgbNavModule,
    NgbModule,
    HeaderComponent,
    FooterComponent,
    MomentModule,
    ChartsModule,
    HttpClientModule,
    CommonModule,
    NgSelectModule,
    RouterModule,
    InfiniteScrollModule,
    FontAwesomeModule,
    StatusIndicatorComponent,
    NgbCollapseModule,
    NgbdSortableHeader,
    SortIndicatorComponent,
    FormatDateTimePipe,
    MetricDisplayComponent,
    CarrierRateChartComponent,
    CarrierComparisonRateChartComponent,
    CorrectiveActionChartComponent,
    CorrectiveActionChartModalComponent,
    DiscrepancyChartComponent,
    DiscrepancyChartModalComponent,
    StationChartComponent,
    StationChartModalComponent,
    DelayMinuteChartComponent,
    FilterGlobalPipe,
    HazardChartComponent,
    RollingRateComponent,
    OdiResultsChartComponent,
    BarChartModalComponent, OdiResultDriverComponent, OdiDriverComponent, OdiCodeComponent,
    OdiResultDriverModalComponent
  ],
})
export class SharedModule { }
