<nav class="navbar navbar-expand-sm navbar-dark"
     *ngIf="user$ | async as user">
  <a class="navbar-brand title"
     routerLink="reliability-index"
     href="#">DC Reliability</a>
  <button class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse"
       id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link"
           routerLinkActive="active"
           routerLink="dashboard"
           href="#">Delay/Cancel</a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
           routerLinkActive="active"
           routerLink="odi-dashboard"
           href="#">ODI</a>
      </li>

      <li class="nav-item"
          *ngIf="
          user.isFOMDCCFREL_UrDccPrdReliabilityIndexAdmin ||
          user.isFOMDCCFREL_UrDccPrdReliabilityIndexAnalyst ||
          user.isFOMDCCFREL_DccPrdPortalAdmin ||
          user.isFOMDCCFREL_DccPrdReliabilityAdmin ||
          user.isFOMDCCFREL_DccPrdPinnacleOccStaff ||
          user.isFOMDCCFREL_DccPrdSkywestOccStaff ||
          user.isFOMDCCFREL_DccPrdRepublicAirlinesOccStaff ||
          user.isFOMDCCFREL_DccPrdGoJetOccStaff ||
          user.isFOMDCCFREL_DccPrdCompassOccStaff
        ">
        <a class="nav-link"
           routerLinkActive="active"
           routerLink="carrier-upload"
           href="#">Carrier Upload</a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
           routerLinkActive="active"
           routerLink="odi-index"
           href="#">ODI Index</a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
           routerLinkActive="active"
           routerLink="reliability-index"
           href="#">Reliability Index</a>
      </li>
      <li class="nav-item"  *ngIf="
      user.isFOMDCCFREL_UrDccPrdReliabilityIndexAdmin || user.isFOMDCCFREL_DccPrdPinnacleOccStaff
    ">
        <a class="nav-link"
           routerLinkActive="active"
           routerLink="msg-dashboard"
           href="#">RTM</a>
      </li>
      <li class="nav-item" *ngIf="
      user.isFOMDCCFREL_UrDccPrdReliabilityIndexAdmin || user.isFOMDCCFREL_DccPrdPinnacleOccStaff
    ">
        <a class="nav-link"
           routerLinkActive="active"
           routerLink="mdc-dashboard"
           href="#">MDC</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"
           routerLinkActive="active"
           routerLink="reliability-report"
           href="#">Report</a>
      </li>

      <li class="nav-item">
        <a class="nav-link"
           routerLink="flight-utilization"
           routerLinkActive="active"
           href="#"
           *ngIf="(user.isFOMDCCFREL_UrDccPrdReliabilityIndexAdmin || user.isFOMDCCFREL_DccPrdReliabilityAdmin || user.isFOMDCCFREL_UrDccPrdReliabilityIndexAnalyst) && !(
            user.isFOMDCCFREL_DccPrdPinnacleOccStaff ||
            user.isFOMDCCFREL_DccPrdSkywestOccStaff ||
            user.isFOMDCCFREL_DccPrdRepublicAirlinesOccStaff ||
            user.isFOMDCCFREL_DccPrdGoJetOccStaff ||
            user.isFOMDCCFREL_DccPrdCompassOccStaff)">Flight Utilization</a>
      </li>

      <li class="nav-item"
          *ngIf="user.isFOMDCCFREL_UrDccPrdReliabilityIndexAdmin">
        <a class="nav-link"
           routerLink="admin"
           routerLinkActive="active"
           href="#">Administration</a>
      </li>
    </ul>
  </div>
</nav>