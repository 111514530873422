import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

import * as moment from 'moment';
import * as _ from 'lodash';

import { ReliabilityEvent } from '../models/reliability-event.model';
import { take } from 'rxjs/operators';
import { IReliabilityEventDto } from '../models/reliability-event.dto.model';
import { IATA } from '../models/ata.dto.model';
import { ConfigurationService, AuthenticationService } from '@com-delta-dcflightreliability/dc-common';
import { IAtaSearch } from '../models/ata-search.model';
import { IUser } from 'src/app/models/user.model';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AtaService {
  public reliabilityEvents$ = new BehaviorSubject<ReliabilityEvent[]>([]);
  public ataScope$ = new BehaviorSubject<number>(4);
  public dataRequested$ = new BehaviorSubject<boolean>(false);
  public dataLoading$ = new BehaviorSubject<boolean>(false);
  public availableCarriers: string[];
  public ataSearchParam$ = new BehaviorSubject<IAtaSearch>(null);
  public user: IUser;
  private sub = new Subscription();

  private parentATAMap = {};
  private parent2DigitATAMap = {};
  private url = this.config.config.apiResource;

  constructor(
    private readonly config: ConfigurationService,
    private httpClient: HttpClient,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService


  ) {
    // Get the Parent ATAs when the service instantiates
    this.getParentATAs();

    // Get 2 digit atas when service instantiates
    this.getParent2DigitATAs();

    this.sub.add(this.authenticationService.user$.subscribe((user) => (this.user = user)));
  }



  public toggleAtaEventScope(scope: number) {

    if (scope === 4) {
      this.reliabilityEvents$.pipe(take(1)).subscribe((events) => {
        const mappedEvents = events.map((event) => {
          event.ata = event.longAta;
          event.description = event.longDescription;
          return event;
        });

        this.reliabilityEvents$.next(mappedEvents);
      });
    }

    if (scope === 3) {
      this.reliabilityEvents$.pipe(take(1)).subscribe((events) => {
        const mappedEvents = events.map((event) => {
          event.ata = event.mediumAta;
          event.description = event.mediumDescription;
          return event;
        });

        this.reliabilityEvents$.next(mappedEvents);
      });
    }

    if (scope === 2) {
      this.reliabilityEvents$.pipe(take(1)).subscribe((events) => {
        const mappedEvents = events.map((event) => {
          event.ata = event.shortAta;
          event.description = event.shortDescription;
          return event;
        });

        this.reliabilityEvents$.next(mappedEvents);
      });
    }
  }

  public getAtaRecords(formValues) {
    // Clean out pre-existing search results
    this.reliabilityEvents$.next([]);
    // Mark data as loading
    this.dataLoading$.next(true);

    const params = {
      start: formValues.start,
      end: formValues.end,
      fleet: formValues.fleet,
    };

    // Set the start and end date ranges
    // params.start = moment(formValues.end)
    //   .subtract(formValues.months, 'months')
    //   .add(1, 'day')
    //   .format('YYYY-MM-DD');

    // Update search stream
    this.ataSearchParam$.next(params);

    // Mark the data as requested
    this.dataRequested$.next(true);

    this.httpClient
      .get<IReliabilityEventDto[]>(`${this.url}/reliability-events`, { params })
      .subscribe(
        (res) => {
          this.dataLoading$.next(false);

          // Gather the unique list of carriers -- skip EV
          this.availableCarriers = _.uniqBy(res, (item) => {
            return item.carrier;
          })
            .map((item) => item.carrier)
            .filter((carrier) => carrier !== 'EV');

            // if(this.isCarrierUser())
            // {
            //   res = res.filter(e => e.carrier == this.getCarrierType())
            // }

          const reliabilityEvents = res.map((item) => {
            const reliabilityEvent = new ReliabilityEvent(item);

            // Set the medium description(3 digit) from a local map
            if (
              this.parentATAMap[reliabilityEvent.mediumAta] &&
              this.parentATAMap[reliabilityEvent.mediumAta].description
            ) {
              reliabilityEvent.mediumDescription = this.parentATAMap[
                reliabilityEvent.mediumAta
              ].description;
            } else {
              reliabilityEvent.mediumDescription = 'Not Found';
            }

            // Set the short description(2 digit) from a local map
            if (
              this.parent2DigitATAMap[reliabilityEvent.shortAta] &&
              this.parent2DigitATAMap[reliabilityEvent.shortAta].description
            ) {
              reliabilityEvent.shortDescription = this.parent2DigitATAMap[
                reliabilityEvent.shortAta
              ].description;
            } else {
              reliabilityEvent.shortDescription = 'Not Found';
            }





            return reliabilityEvent;
          });

          this.reliabilityEvents$.next(reliabilityEvents);
        },
        (err) => {
          this.toastrService.error(err.error.message);
        }
      );
  }

  public isCarrierUser() {
    if (this.user == null) {
      return false;
    }
    return (this.user.isFOMDCCFREL_DccPrdPinnacleOccStaff ||
      this.user.isFOMDCCFREL_DccPrdSkywestOccStaff ||
      this.user.isFOMDCCFREL_DccPrdRepublicAirlinesOccStaff ||
      this.user.isFOMDCCFREL_DccPrdGoJetOccStaff ||
      this.user.isFOMDCCFREL_DccPrdCompassOccStaff);
  }

  public getCarrierType() {
    if (this.user == null) {
      return '';
    }
    else if (this.user.isFOMDCCFREL_DccPrdPinnacleOccStaff) {
      return '9E';
    }
    else if (this.user.isFOMDCCFREL_DccPrdSkywestOccStaff) {
      return 'OO';
    }
    else if (this.user.isFOMDCCFREL_DccPrdRepublicAirlinesOccStaff) {
      return 'YX';
    }
    else if (this.user.isFOMDCCFREL_DccPrdGoJetOccStaff) {
      return 'G7';
    }
    else if (this.user.isFOMDCCFREL_DccPrdCompassOccStaff) {
      return 'CP';
    }
    return '';
  }

  public getParentATAs() {
    this.httpClient
      .get<IATA[]>(`${this.url}/atas?type=parent`)
      .subscribe((atas) => {
        atas.forEach((ata) => {
          const index = ata.code.slice(0, 3);
          this.parentATAMap[index] = ata;
        });
      });
  }

  public getParent2DigitATAs() {
    this.httpClient
      .get<IATA[]>(`${this.url}/atas?type=chapter`)
      .subscribe((atas) => {
        atas.forEach((ata) => {
          const index = ata.code.slice(0, 2);
          this.parent2DigitATAMap[index] = ata;
        });
      });
  }
}
