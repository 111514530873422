import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import * as _ from 'lodash';

import { AtaService } from './ata.service';
import { WeightsService } from './weights.service';
import { ReliabilityEvent } from '../models/reliability-event.model';
import { ReliabilityIndex } from '../models/reliability-index';

@Injectable({
  providedIn: 'root',
})
export class ReliabilityIndexService {
  public ata$ = this.ataService.reliabilityEvents$;
  public weights$ = this.weightsService.weights$;
  public carrierFilter$ = new BehaviorSubject<string>('portfolio');
  public processed$ = new BehaviorSubject<ReliabilityIndex[]>([]);

  private weights;
  private carrierTotals;

  // Common carrier list for loops
  private carriers = ['OO', 'YX', 'G7', 'CP', '9E', 'portfolio'];

  constructor(
    private ataService: AtaService,
    private weightsService: WeightsService
  ) {
    combineLatest(this.ata$, this.weights$).subscribe(([events, weights]) => {
      // Store the weights from the weight service
      this.weights = weights;

      this.createCarrierTotals(events);

      // Group records by ata
      const ataGroup = _.groupBy(events, 'ata');

      // Get list of all ATAs
      const ataList: string[] = Object.keys(ataGroup);

      let processed = [];

      // For each ATA process its reliability events
      ataList.forEach((a) => {
        // Push Reliability records into processed array by creating a reliability index record per ata group
        processed.push(this.createReliabilityRecord(ataGroup[a]));
      });

      processed = processed.map((item) => this.calculatePercentages(item));

      processed = this.addCategoryRankings(processed);

      // Order the results by portfolio impact rank as that is the default selection
      processed = _.orderBy(processed, 'portfolio.impactRank', 'asc');

      // Next in the value for processed data
      this.processed$.next(processed);
    });
  }

  private createCarrierTotals(events: ReliabilityEvent[]) {
    // Group records by carrier
    const carrierGroup = _.groupBy(events, 'carrier');

    // Set initial carrier totals to zero
    this.carrierTotals = {
      portfolio: {
        totalDelays: 0,
        totalCancellations: 0,
        totalD180: 0,
        totalAos: 0,
        totalDelayMinutes: 0,
        totalFFD0: 0,
      },
      OO: {
        totalDelays: 0,
        totalCancellations: 0,
        totalD180: 0,
        totalAos: 0,
        totalDelayMinutes: 0,
        totalFFD0: 0,
      },
      YX: {
        totalDelays: 0,
        totalCancellations: 0,
        totalD180: 0,
        totalAos: 0,
        totalDelayMinutes: 0,
        totalFFD0: 0,
      },
      G7: {
        totalDelays: 0,
        totalCancellations: 0,
        totalD180: 0,
        totalAos: 0,
        totalDelayMinutes: 0,
        totalFFD0: 0,
      },
      CP: {
        totalDelays: 0,
        totalCancellations: 0,
        totalD180: 0,
        totalAos: 0,
        totalDelayMinutes: 0,
        totalFFD0: 0,
      },
      '9E': {
        totalDelays: 0,
        totalCancellations: 0,
        totalD180: 0,
        totalAos: 0,
        totalDelayMinutes: 0,
        totalFFD0: 0,
      },
    };

    ['OO', 'YX', 'G7', 'CP', '9E'].forEach((carrier) => {
      if (carrierGroup[carrier]) {
        carrierGroup[carrier].forEach((event) => {
          // Aggregate the number of delays
          if (!event.isCancellation) {
            this.carrierTotals[carrier].totalDelays += 1;
            this.carrierTotals.portfolio.totalDelays += 1;
          }

          // Aggregate the number of cancellations
          if (event.isCancellation) {
            this.carrierTotals[carrier].totalCancellations += 1;
            this.carrierTotals.portfolio.totalCancellations += 1;
          }

          // Aggregate the number of delays
          if (event.firstFlight) {
            this.carrierTotals[carrier].totalFFD0 += 1;
            this.carrierTotals.portfolio.totalFFD0 += 1;
          }

          // Aggregate the number of AOS events
          if (event.delayMinutes >= 30) {
            this.carrierTotals[carrier].totalAos += 1;
            this.carrierTotals.portfolio.totalAos += 1;
          }

          // Aggregate the number of D180 events
          if (event.delayMinutes >= 180) {
            this.carrierTotals[carrier].totalD180 += 1;
            this.carrierTotals.portfolio.totalD180 += 1;
          }

          // Aggregate delay minutes
          this.carrierTotals[carrier].totalDelayMinutes += event.delayMinutes;
          this.carrierTotals.portfolio.totalDelayMinutes += event.delayMinutes;
        });
      }
    });
  }

  private sortAndRank(array, property: string, rankType: string) {
    const orderedItems = _.orderBy(array, property, 'asc');

    // Get count of unique values - used for the highest rank
    const uniqueEventATAs = _.uniqBy(orderedItems, property);
    let rank = uniqueEventATAs.length;

    const hasZeros = uniqueEventATAs
      .map((item) => item[property])
      .some((i) => i === 0);

    if (!hasZeros) {
      rank++;
    }

    let previousValue = null;

    orderedItems.forEach((item) => {
      if (item[property] > previousValue) {
        rank--;
      }

      // Set the rank if the quantity is greater than zero; otherwise set a default value
      item[rankType] = item[property] > 0 ? rank : '-';

      // Set the previous value for the next iteration's comparison
      previousValue = item[property];
    });
  }

  private addCategoryRankings(processed: ReliabilityEvent[]) {
    this.carriers.forEach((carrier) => {
      const tempArray = [];

      processed.forEach((item) => {
        tempArray.push(item[carrier]);
      });

      this.sortAndRank(tempArray, 'delayCount', 'delayRank');
      this.sortAndRank(tempArray, 'cancellationCount', 'cancellationRank');
      this.sortAndRank(tempArray, 'aosCount', 'aosRank');
      this.sortAndRank(tempArray, 'd180Count', 'd180Rank');
      this.sortAndRank(tempArray, 'delayMinutes', 'delayMinutesRank');
      this.sortAndRank(tempArray, 'ffd0Count', 'ffd0Rank');
      this.sortAndRank(tempArray, 'impactScore', 'impactRank');
    });

    return processed;
  }

  private createReliabilityRecord(ataEvents: ReliabilityEvent[]) {
    const reliabilityIndex: ReliabilityIndex = {
      ata: '',
      description: '',
      portfolio: this.calculateMetrics(ataEvents, 'portfolio'),
      OO: this.calculateMetrics(ataEvents, 'OO'),
      CP: this.calculateMetrics(ataEvents, 'CP'),
      YX: this.calculateMetrics(ataEvents, 'YX'),
      G7: this.calculateMetrics(ataEvents, 'G7'),
      '9E': this.calculateMetrics(ataEvents, '9E'),
    };

    // Set global values for each ATA
    ataEvents.forEach((ata: ReliabilityEvent) => {
      // Set ATA name
      reliabilityIndex.ata = ata.ata;
      reliabilityIndex.description = ata.description;
    });

    return reliabilityIndex;
  }

  private calculateMetrics(records, carrier: string) {
    const metrics = {
      delayMinutes: 0,
      delayCount: 0,
      cancellationCount: 0,
      aosCount: 0,
      d180Count: 0,
      ffd0Count: 0,
    };

    let filteredRecords = [];

    // Filter records by carrier argument
    if (carrier !== 'portfolio') {
      filteredRecords = records.filter((r) => r.carrier === carrier);
    } else {
      filteredRecords = records;
    }

    // Loop through each event and perform calculations
    filteredRecords.forEach((r) => {
      // Calculate Delay Minutes
      metrics.delayMinutes += r.delayMinutes;

      // Count the number of delays
      if (!r.isCancellation) {
        metrics.delayCount += 1;
      }

      // Count the number of cancellations
      if (r.isCancellation) {
        metrics.cancellationCount += 1;
      }

      // Count the number of AOS events
      if (r.delayMinutes >= 30) {
        metrics.aosCount += 1;
      }

      // Count the number of D180 events
      if (r.delayMinutes >= 180) {
        metrics.d180Count += 1;
      }

      // Count the number of first flight events
      if (r.firstFlight) {
        metrics.ffd0Count += 1;
      }
    });

    return metrics;
  }

  // This must be called after the data values have been set
  private calculatePercentages(record) {
    this.carriers.forEach((carrier) => {
      // Cancellation Percentage
      record[carrier].cancellationPercentage =
        record[carrier].cancellationCount /
        this.carrierTotals[carrier].totalCancellations;

      // If the cancellation calculation fails to yield a result, set it to zero
      if (!record[carrier].cancellationPercentage) {
        record[carrier].cancellationPercentage = 0;
      }

      // Delay Percentage
      record[carrier].delayPercentage =
        record[carrier].delayCount / this.carrierTotals[carrier].totalDelays;

      // If the delay percentage calculation fails to yield a result, set it to zero
      if (!record[carrier].delayPercentage) {
        record[carrier].delayPercentage = 0;
      }

      // AOS Percentage
      record[carrier].aosPercentage =
        record[carrier].aosCount / this.carrierTotals[carrier].totalAos;

      // If the AOS percentage calculation fails to yield a result, set it to zero
      if (!record[carrier].aosPercentage) {
        record[carrier].aosPercentage = 0;
      }

      // D180 Percentage
      record[carrier].d180Percentage =
        record[carrier].d180Count / this.carrierTotals[carrier].totalD180;

      // If the D180 percentage calculation fails to yield a result, set it to zero
      if (!record[carrier].d180Percentage) {
        record[carrier].d180Percentage = 0;
      }

      // First Flight Percentage
      record[carrier].ffd0Percentage =
        record[carrier].ffd0Count / this.carrierTotals[carrier].totalFFD0;

      // If the First Flight percentage calculation fails to yield a result, set it to zero
      if (!record[carrier].ffd0Percentage) {
        record[carrier].ffd0Percentage = 0;
      }

      // Delay Minutes Average
      record[carrier].delayMinutesAverage =
        record[carrier].delayMinutes / record[carrier].delayCount;

      // If the delay minutes calculation fails to yield a result, set it to zero
      if (!record[carrier].delayMinutesAverage) {
        record[carrier].delayMinutesAverage = 0;
      }

      // Delay Minutes Percentage
      record[carrier].delayMinutesPercentage =
        record[carrier].delayMinutes /
        this.carrierTotals[carrier].totalDelayMinutes;

      // If the Delay Minutes percentage calculation fails to yield a result, set it to zero
      if (!record[carrier].delayMinutesPercentage) {
        record[carrier].delayMinutesPercentage = 0;
      }

      // Calculate Impact Scores
      record[carrier].impactScore =
        record[carrier].delayCount * this.weights.delays +
        record[carrier].aosCount * this.weights.aos +
        record[carrier].d180Count * this.weights.d180 +
        record[carrier].ffd0Count * this.weights.ffd0 +
        record[carrier].cancellationCount * this.weights.cancellations;
    });

    return record;
  }
}
