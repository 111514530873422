import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './modules/shared/shared.module';

// Configuration
import {
  ConfigurationService,
  SecurityModule,
  TokenInterceptor,
} from '@com-delta-dcflightreliability/dc-common';

// Components
import { AppComponent } from './app.component';

// Services
import { ReliabilityIndexService } from './services/reliability-index.service';
import { HttpCustomInterceptorService } from './services/http-custom-interceptor.service';

// Initialization Factory
export function init_app(configService: ConfigurationService) {
  return () => configService.initConfig();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    SecurityModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
  ],
  providers: [
    ReliabilityIndexService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [ConfigurationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCustomInterceptorService,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
