import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class NgbDateCustomAdapter extends NgbDateAdapter<string> {
  // Convert to NgbDateStruct fromModel
  fromModel(date: string): NgbDateStruct {
    if (!date) {
      return null;
    }
    const [year, month, day] = date.split('-').map(Number);
    if (!year || !month || !day) {
      return null;
    }
    return { year, month, day };
  }

  // Convert to string
  toModel(date: NgbDateStruct): string {
    return date ? `${date.year}-${String('00' + date.month).slice(-2)}-${String('00' + date.day).slice(-2)}` : null;
  }


  // Convert to NgbDateStruct
  // fromModel(date: string): NgbDateStruct {
  //   return date &&
  //     Number(date.substring(0, 4)) &&
  //     Number(date.substring(5, 7) + 1) &&
  //     Number(date.substring(8, 10))
  //     ? {
  //       year: Number(date.substring(0, 4)),
  //       month: Number(date.substring(5, 7)),
  //       day: Number(date.substring(8, 10)),
  //     }
  //     : null;
  // }

  // toModel(date: NgbDateStruct): string {
  //   return date
  //     ? date.year.toString() +
  //     '-' +
  //     String('00' + date.month).slice(-2) +
  //     '-' +
  //     String('00' + date.day).slice(-2)
  //     : null;
  // }
}

@Injectable()
export class NgbDateMomentParserFormatter extends NgbDateParserFormatter {
  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const d = moment({ year: date.year, month: date.month - 1, date: date.day });
    return d.isValid() ? d.format('YYYY-MM-DD') : '';
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }
    const d = moment(value, 'YYYY-MM-DD');
    return d.isValid() ? { year: d.year(), month: d.month() + 1, day: d.date() } : null;
  }


  // format(date: NgbDateStruct): string {
  //   if (date === null) {
  //     return '';
  //   }

  //   const d = moment({
  //     year: date.year,
  //     month: date.month - 1,
  //     date: date.day,
  //   });

  //   return d.isValid() ? d.format('YYYY-MM-DD') : '';
  // }

  // parse(value: string): NgbDateStruct {
  //   if (!value) {
  //     return null;
  //   }

  //   const d = moment(value, 'LL');

  //   return d.isValid()
  //     ? {
  //       year: d.year(),
  //       month: d.month() + 1,
  //       day: d.date(),
  //     }
  //     : null;
  // }

}



