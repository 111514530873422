import { PipeTransform, Pipe } from '@angular/core';
import { IScenario } from 'src/app/models/scenario.dto.model';

@Pipe({
  name: 'filterGlobal',
  pure: true,
})
export class FilterGlobalPipe implements PipeTransform {
  transform(items: IScenario[], filter: boolean) {
    if (!items) {
      return;
    }
    return items.filter((item) => item.isShared === filter);
  }
}
