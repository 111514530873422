import { IReliabilityEventDto } from './reliability-event.dto.model';

export class ReliabilityEvent {
  public ata: string;
  public description: string;
  public longDescription: string;
  public mediumDescription: string;
  public shortDescription: string;
  public longAta: string;
  public mediumAta: string;
  public shortAta: string;
  public delayMinutes: number;
  public isCancellation: boolean;
  public carrier: string;
  public firstFlight: boolean;

  constructor(obj: IReliabilityEventDto) {
    this.longDescription = obj.ata.description;
    this.description = obj.ata.description;
    this.ata = obj.ata.code.toString();
    this.longAta = obj.ata.code.toString();
    this.mediumAta = obj.ata.code.toString().slice(0, 3);
    this.shortAta = obj.ata.code.toString().slice(0, 2);
    this.delayMinutes = obj.delayMinutes || 0;
    this.carrier = obj.carrier;
    this.isCancellation = obj.isCancellation;
    this.firstFlight = obj.firstFlight || false;
  }
}
