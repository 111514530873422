import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '@com-delta-dcflightreliability/dc-common';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class WeightsService {
  public weights$ = new BehaviorSubject<any>(null);
  private url = this.config.config.apiResource;

  constructor(
    private httpClient: HttpClient,
    private config: ConfigurationService,
    private toastrService: ToastrService
  ) {}

  public getWeights() {
    this.httpClient.get<any>(`${this.url}/weights?latest=true`).subscribe(
      (res) => {
        this.weights$.next(res[0]);
      },
      (err) => console.log(err)
    );
  }

  public createWeights(formValue) {
    const payload = formValue;

    this.httpClient.post<any>(`${this.url}/weights`, payload).subscribe(
      (res) => {
        this.weights$.next(res);
        this.toastrService.success('Weights Saved');
      },
      (err) => console.log(err)
    );
  }
}
