import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, LinearTickOptions, Chart } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color } from 'ng2-charts';
import * as _ from 'lodash';

import { CarrierChartService } from 'src/app/services/carrier-chart.service';
import { IChart } from 'src/app/models/chart.model';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hazard-chart',
  templateUrl: './hazard-chart.component.html',
  styleUrls: ['./hazard-chart.component.scss']
})
export class HazardChartComponent implements OnInit {
  @Input() chartDetails;
  public chartModalRef: NgbModalRef;
  public showModalLink = false;

  public chartData: IChart;
  public barChartLabels: string[];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: Chart.ChartDataSets[];
  public barChartColor: Color[];
  public tickOptions: LinearTickOptions = {
    beginAtZero: true,
    precision: 0,
    maxTicksLimit: 5
  };
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    title: {
      display: true,
      text: '',
      padding: 25
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 12,
          callback: (value) => {
            // Update xAxes lable string
            return this.carrierChartService.setCorrectiveActionLabel(value);
          }
        },
        stacked: true
      }], yAxes: [{
        ticks: this.tickOptions,
        scaleLabel: {
          display: true,
          labelString: '',
          fontSize: 10
        },
        gridLines: {
          offsetGridLines: false
        },
        stacked: true
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        display: false,
        font: {
          size: 12
        }
      }
    },
    legend: {
      display: true,
      position: 'bottom',
    },
    elements: {
      point: {
        pointStyle: 'dash'
      }
    },
    animation: {
      onComplete() {
        const chartInstance = this.chart;
        const ctx = chartInstance.ctx;

        const fontSize = 12;
        const fontStyle = '200';
        const fontFamily = 'Open Sans';
        ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.fillStyle = 'black';

        const datasetLength = this.data.datasets.length;
        const meta = chartInstance.controller.getDatasetMeta(datasetLength - 1);
        const dataset = this.data.datasets[datasetLength - 1];
        meta.data.forEach((bar, index) => {
          if (dataset.data[index] !== 0) {
            const data = dataset.data[index];
            ctx.fillText(Math.trunc(data * Math.pow(10, 2)) / Math.pow(10, 2), bar._model.x, bar._model.y - 20);
          }
        });
      },
      onProgress() {
        const chartInstance = this.chart;
        const ctx = chartInstance.ctx;

        const fontSize = 12;
        const fontStyle = '200';
        const fontFamily = 'Open Sans';
        ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.fillStyle = 'black';

        const datasetLength = this.data.datasets.length;
        const meta = chartInstance.controller.getDatasetMeta(datasetLength - 1);
        const dataset = this.data.datasets[datasetLength - 1];
        meta.data.forEach((bar, index) => {
          if (dataset.data[index] !== 0) {
            const data = dataset.data[index];
            ctx.fillText(Math.trunc(data * Math.pow(10, 2)) / Math.pow(10, 2), bar._model.x, bar._model.y - 20);
          }
        });
      }
    },

  };
  constructor(private carrierChartService: CarrierChartService) {

  }

  ngOnInit(): void {
    if (this.chartDetails) {
      this.chartData = _.cloneDeep(this.chartDetails);
      // Update chart data
      this.barChartOptions.title.text = this.chartData.title;
      this.barChartLabels = this.chartData.chartLabel;
      this.barChartData = this.chartData.chartDataSet;
      this.barChartColor = this.chartData.chartColors;

      _.forEach(this.chartData.chartDataSet, value => {
        // Update Bar formatting
        value.maxBarThickness = 50;
        value.barPercentage = 0.5;
        value.barThickness = 'flex';
      });


      const lineChartObject = {
        type: 'line', data: this.setDataAry(this.chartData.chartDataSet), label: 'Total', borderColor: 'black', fill: false,
        borderWidth: 1, borderDash: [4, 2], lineTension: 0
      };

      // Iterate yAxes option array and set scale label
      _.forEach(this.barChartOptions.scales.yAxes, value => {
        if (value.scaleLabel) {
          value.scaleLabel.labelString = this.chartData.yaxisScaleLabel;
        }
      });
      this.chartData.chartDataSet.push(lineChartObject);
      const lineChartRecord = _.last(this.chartData.chartDataSet);
      lineChartRecord.borderWidth = 1;
      this.barChartData = this.chartData.chartDataSet;
    }
  }

  private setDataAry(dataset) {
    if (dataset) {
      const datasetLength = dataset.length;
      const length = dataset[0].data.length;
      const tempAry = [];
      for (let i = 0; i < length; i++) {
        let temp = 0;
        for (let j = 0; j < datasetLength; j++) {
          temp = temp + dataset[j].data[i];
        }
        tempAry.push(temp);
      }
      return tempAry;
    }
  }

}
