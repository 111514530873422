import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationService } from '@com-delta-dcflightreliability/dc-common';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';

import { ICarrierChart } from 'src/app/models/carrier-chart.model';
import { ChartType } from 'src/app/models/chart.model';

@Injectable({
  providedIn: 'root'
})
export class CarrierChartService {
  private url = this.config.config.apiResource;
  public loadingCarrierChart$ = new BehaviorSubject<boolean>(false);
  public loadingCarrierComparisonChart$ = new BehaviorSubject<boolean>(false);
  public loadingCorrectiveChart$ = new BehaviorSubject<boolean>(false);
  public loadingCorrectiveChartSummary$ = new BehaviorSubject<boolean>(false);
  public carrierRateChart$ = new BehaviorSubject<any>(null);
  public carrierComparisonChart$ = new BehaviorSubject<any>(null);
  public correctiveActionChart$ = new BehaviorSubject<any>(null);
  public correctiveActionChartSummary$ = new BehaviorSubject<any>(null);
  public reliabilityChartReport$ = new BehaviorSubject<any>(null);
  public reliabilityChartParams$ = new BehaviorSubject<any>(null);
  public odiChartParams$ = new BehaviorSubject<any>(null);
  public loadingOdiPerformance$ = new BehaviorSubject<boolean>(false);
  public odiPerformanceSummary$ = new BehaviorSubject<any>(null);
  public OdiPerformanceFlag$ = new BehaviorSubject<boolean>(false);

  private fill = false;
  private lineTension = 0;
  private borderCapStyle = 'butt';

  constructor(
    private httpClient: HttpClient,
    private readonly config: ConfigurationService,
    private toastrService: ToastrService) { }

  // Carrier chart
  public getCarrierRateChart(params) {
    this.loadingCarrierChart$.next(true);
    this.carrierRateChart$.next(null);

    // Update chart type
    params.graphType = ChartType.carrierRate;

    this.httpClient
      .get<ICarrierChart>(`${this.url}/reliability-index-graphs`, { params })
      .pipe(finalize(() => this.loadingCarrierChart$.next(false)))
      .subscribe(
        (res) => {
          // Create chart object
          const chartObj = this.setChartDeta(res);

          // Set carrier chart object to observable
          this.carrierRateChart$.next(chartObj);
        },
        (err) => {
          this.toastrService.error(err.error.message);
        });
  }

  // Carrier comparison chart
  public getCarrierComparisonChart(params) {
    this.loadingCarrierComparisonChart$.next(true);
    this.carrierComparisonChart$.next(null);

    // Update chart type
    params.graphType = ChartType.comparison;

    this.httpClient
      .get<ICarrierChart>(`${this.url}/reliability-index-graphs`, { params })
      .pipe(finalize(() => this.loadingCarrierComparisonChart$.next(false)))
      .subscribe(
        (res) => {
          // Create chart object
          const chartObj = this.setChartDeta(res);

          // Set comparison chart object to observable
          this.carrierComparisonChart$.next(chartObj);
        },
        (err) => {
          this.toastrService.error(err.error.message);
        });
  }

  // Corrective Action chart
  public getCorrectiveActionChart(params) {
    this.loadingCorrectiveChart$.next(true);
    this.correctiveActionChart$.next(null);

    // Update chart type
    params.graphType = ChartType.correctiveAction;

    this.httpClient
      .get<ICarrierChart>(`${this.url}/reliability-index-graphs`, { params })
      .pipe(finalize(() => this.loadingCorrectiveChart$.next(false)))
      .subscribe(
        (res) => {
          // Create chart object
          const chartObj = this.setChartDeta(res);

          // Set corrective action chart object to observable
          this.correctiveActionChart$.next(chartObj);
        },
        (err) => {
          this.toastrService.error(err.error.message);
        });
  }

  // Corrective action chart for modal
  public getCorrectiveActionChartForSummary(params) {
    this.loadingCorrectiveChartSummary$.next(true);
    this.correctiveActionChartSummary$.next(null);

    // Update chart type
    params.graphType = ChartType.correctiveAction;

    this.httpClient
      .get<ICarrierChart>(`${this.url}/reliability-index-graphs`, { params })
      .pipe(finalize(() => this.loadingCorrectiveChartSummary$.next(false)))
      .subscribe(
        (res) => {
          // Create chart object
          const chartObj = this.setChartDeta(res);

          // Set corrective action chart object to observable
          this.correctiveActionChartSummary$.next(chartObj);
        },
        (err) => {
          this.toastrService.error(err.error.message);
        });
  }

  public setChartDeta(data: ICarrierChart) {
    const lineChartDataSet = [];
    const chartColors = [];
    const chartcarrierColors = [];


    // Iterate response and Create chart object
    _.forEach(data.dataset, (value) => {
      const chartData = {
        label: value.label,
        data: value.yaxisValue,
        fill: this.fill,
        lineTension: this.lineTension,
        borderCapStyle: this.borderCapStyle
      };
      const colorsObj = {
        borderColor: value.colorCode,
        backgroundColor: value.colorCode
      };
      const colorsObjcarrier = {
        borderColor: value.colorCode,
        backgroundColor: value.CarrierColorCode
      };

      lineChartDataSet.push(chartData);
      chartColors.push(colorsObj);
      chartcarrierColors.push(colorsObjcarrier);
    });

    const chartObjectMap = {
      title: data.title,
      chartLabel: data.xaxisValue,
      yaxisScaleLabel: data.yaxisLabel,
      chartDataSet: [...lineChartDataSet],
      chartColors: [...chartColors],
      chartcarrierColors: [...chartcarrierColors]
    };
    return chartObjectMap;
  }

  public setCorrectiveActionLabel(value, flag = false) {
    // Label value is either string or number
    if (typeof value === 'string') {
      if (flag) {
        return _.truncate(value, { length: 7 });
      } else {
        if (value.indexOf('~') > -1) {
          const labelValue = _.split(value, '~');
          return _.truncate(_.head(labelValue), { length: 16 }) + '-' + _.truncate(_.last(labelValue), { length: 8 });
        } else {
          return _.truncate(value, { length: 25 });
        }
      }
    } else {
      return value;
    }
  }

  public getChartDetails(param) {
    // clone common params to chart specific
    const correctiveActionParam = _.clone(param);
    const comparisonParam = _.clone(param);
    const carrierParam = _.clone(param);
    const discrepancyParam = _.clone(param);
    const stationParam = _.clone(param);
    const delayMinuteParam = _.clone(param);

    // Assign chart type
    correctiveActionParam.graphType = ChartType.correctiveAction;
    comparisonParam.graphType = ChartType.comparison;
    carrierParam.graphType = ChartType.carrierRate;
    discrepancyParam.graphType = ChartType.discrepancy;
    stationParam.graphType = ChartType.station;
    delayMinuteParam.graphType = ChartType.delayMinutes;

    // all chart http call
    const comparisonHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/reliability-index-graphs`, { params: comparisonParam });
    const carrierRateHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/reliability-index-graphs`, { params: carrierParam });
    const correctiveActionHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/reliability-index-graphs`, { params: correctiveActionParam });
    const discrepancyHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/reliability-index-graphs`, { params: discrepancyParam });
    const stationHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/reliability-index-graphs`, { params: stationParam });
    const delayMinuteHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/reliability-index-graphs`, { params: delayMinuteParam });

    // forkjoin all three charts api call
    return forkJoin([comparisonHttp, carrierRateHttp, correctiveActionHttp, discrepancyHttp, stationHttp, delayMinuteHttp]);
  }

  public getOdiIndexChartDetails(param) {
    // clone common params to chart specific
    const correctiveActionParam = _.clone(param);
    const comparisonTwelveMonthParam = _.clone(param);
    const comparisonThreeMonthParam = _.clone(param);
    const comparisonOneMonthParam = _.clone(param);
    const carrierParam = _.clone(param);
    const discrepancyParam = _.clone(param);
    const tailsParam = _.clone(param);
    const hazardParam = _.clone(param);
    const rollingRateParam = _.clone(param);
    const odiResultParam = _.clone(param);
    const odiResultDriverParam = _.clone(param);
    const odiCodeParam = _.clone(param);
    const odiDriverParam = _.clone(param);

    // Assign chart type
    correctiveActionParam.graphType = ChartType.correctiveAction;
    comparisonTwelveMonthParam.graphType = ChartType.comparison;
    comparisonTwelveMonthParam.rollingMonths = 12;
    comparisonThreeMonthParam.graphType = ChartType.comparison;
    comparisonThreeMonthParam.rollingMonths = 3;
    comparisonOneMonthParam.graphType = ChartType.comparison;
    comparisonOneMonthParam.rollingMonths = 1;
    carrierParam.graphType = ChartType.carrierRate;
    discrepancyParam.graphType = ChartType.discrepancy;
    tailsParam.graphType = ChartType.tails;
    hazardParam.graphType = ChartType.hazard;
    rollingRateParam.graphType = ChartType.rollingRate;
    odiResultParam.graphType = ChartType.odiResults;
    odiResultDriverParam.graphType = ChartType.odiResultDriver;
    odiCodeParam.graphType = ChartType.odiCode;
    odiDriverParam.graphType = ChartType.odiDriver;

    // all chart http call
    const comparison12Http = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: comparisonTwelveMonthParam });
    const comparison3Http = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: comparisonThreeMonthParam });
    const comparison1Http = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: comparisonOneMonthParam });
    const carrierRateHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: carrierParam });
    const correctiveActionHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: correctiveActionParam });
    const discrepancyHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: discrepancyParam });
    const tailsHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: tailsParam });
    const hazardHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: hazardParam });
    const rollingRateHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: rollingRateParam });
    const odiResultHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: odiResultParam });
    const odiResultDriverHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: odiResultDriverParam });
    const odiCodeHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: odiCodeParam });
    const odiDriverHttp = this.httpClient
      .get<ICarrierChart>(`${this.url}/odi-index-graphs`, { params: odiDriverParam });

    // forkjoin all three charts api call
    return forkJoin([comparison12Http, comparison3Http, comparison1Http, carrierRateHttp,
      correctiveActionHttp, discrepancyHttp, tailsHttp, hazardHttp, rollingRateHttp,
      odiResultHttp, odiResultDriverHttp, odiCodeHttp, odiDriverHttp]);
  }

  /* Calculates group labels to a scaled array so that they can align better */
  calculateGroupLabels(data){
    const scaleFactor = 100;

    const labels = _.chain(data)
    .groupBy((elem) => elem[0])
    .map((entriesOnSameGroup, key) => {
        const newSize = entriesOnSameGroup.length * scaleFactor;
        const newArray = new Array(newSize);
        newArray[0] = '';
        newArray[newArray.length - 1] = '';
        newArray[parseInt(((newArray.length - 1) / 2).toString())] = key;
        return newArray;
    }).flatten().value();
    return labels;
  }

  /* Create Labels array from string */
  createLabelsArray(data) {
    const temp = [];
    _.forEach(data, value => {
      temp.push(_.split(value, '~'));
    });
    return temp;
  }

  /**
   * ODI Performance dashboard API
   */
  getOdiPerformanceDetail(params) {
    this.loadingOdiPerformance$.next(true);
    this.odiPerformanceSummary$.next(null);

    this.httpClient
      .get<any>(`${this.url}/odi-tech-ops-graphs`, { params })
      .pipe(finalize(() => this.loadingOdiPerformance$.next(false)))
      .subscribe(
        (res) => {
          // Create chart object
          const chartObj = {
            techOpsPerformance: res.techOpsPerformance ? this.setChartDeta(res.techOpsPerformance) : res.techOpsPerformance,
            odiCountRecord: res.odiCountRecord,
            techOpsRate: res.techOpsRate ? this.setChartDeta(res.techOpsRate) : res.techOpsRate
          };
          // Set corrective action chart object to observable
          this.odiPerformanceSummary$.next(chartObj);
        },
        (err) => {
          this.toastrService.error(err.error.message);
        });
  }
}
