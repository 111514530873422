import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, LinearTickOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color } from 'ng2-charts';
import * as _ from 'lodash';

import { CarrierChartService } from '../../services/carrier-chart.service';
import { IChart } from '../../models/chart.model';
import { NgbModalRef, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-odi-result-driver-modal',
  templateUrl: './odi-result-driver-modal.component.html',
  styleUrls: ['./odi-result-driver-modal.component.scss']
})
export class OdiResultDriverModalComponent implements OnInit {
  public chartModalRef: NgbModalRef;
  public datalength = 0;
  public originalChartData;
  public chartParams;
  @Input() data: IChart;

  public chartData: IChart;
  public barChartLabels: any;
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: Chart.ChartDataSets[];
  public barChartColor: Color[];
  public tickOptions: LinearTickOptions = {
    beginAtZero: true,
    precision: 0,
    maxTicksLimit: 5
  };
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    title: {
      display: true,
      text: '',
      padding: 25
    },
    scales: {
      xAxes: [
        {
          id: 'xAxis1',
          type: 'category',
          // offset: true,
          gridLines: {
            drawOnChartArea: false,
            tickMarkLength: 50,
            display: false
          },
          ticks: {
            padding: -40,
            autoSkip: false,
            maxRotation: 90,
            minRotation: 90,
            fontSize: 10,
            callback: (label) => {
              return this.carrierChartService.setCorrectiveActionLabel(label, true);
            }
          }
        },
        {
          id: 'xAxis2',
          type: 'category',
          offset: true,
          gridLines: {
            drawOnChartArea: false,
            offsetGridLines: true,
            // tickMarkLength: 40,
            lineWidth: 2,
            color: '#c0c0c0'
          },
          ticks: {
            padding: -8,
            maxRotation: 90,
            autoSkip: false,
            fontSize: 10,
            minRotation: 90,
            fontStyle: 'bold',
            callback(label) {
              return label;
            }
          }
        }
      ],
      yAxes: [{
        ticks: this.tickOptions,
        scaleLabel: {
          display: true,
          labelString: '',
          fontSize: 10
        },
        gridLines: {
          offsetGridLines: false
        }
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        display: true,
        font: {
          size: 12
        }
      }
    }
  };

  constructor(private carrierChartService: CarrierChartService, public modal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.data) {
      console.log(this.data);
      this.chartData = _.cloneDeep(this.data);
      // Update chart data
      const chartLabels = this.carrierChartService.createLabelsArray(this.chartData.chartLabel);
      this.barChartOptions.title.text = this.chartData.title;
      this.barChartLabels = chartLabels;
      this.barChartData = this.chartData.chartDataSet;
      this.barChartColor = this.chartData.chartColors;

      let datalength;
      _.forEach(this.chartData.chartDataSet, value => {
        // Update Bar formatting
        value.maxBarThickness = 50;
        value.barPercentage = 0.5;
        value.barThickness = 'flex';
        value.categoryPercentage = 0.8;

        datalength = value.data.length;

        if (this.datalength > 10) {
          // Update bar label font size based on response data
          if (this.datalength > 10 && this.datalength < 25) {
            // tslint:disable-next-line: no-string-literal
            this.barChartOptions.plugins.datalabels.font['size'] = 10;
          } else if (this.datalength > 25) {
            // tslint:disable-next-line: no-string-literal
            this.barChartOptions.plugins.datalabels.font['size'] = 8;
          }
        }
      });

      // Iterate yAxes option array and set scale label
      _.forEach(this.barChartOptions.scales.yAxes, value => {
        if (value.scaleLabel) {
          value.scaleLabel.labelString = this.chartData.yaxisScaleLabel;
        }
      });

      // Iterate xAxes option array and set scale label
      _.forEach(this.barChartOptions.scales.xAxes, value => {
        if (value.id === 'xAxis1') {
          value.labels = this.barChartLabels.map((entry => entry[1]));
        } else {
          value.labels = this.carrierChartService.calculateGroupLabels(this.barChartLabels);
        }
        if (this.datalength > 10) {
          if (value.ticks) {
            // Update xAxis label font size based on response data
            if (this.datalength > 10 && this.datalength < 18) {
              value.ticks.fontSize = 12;
            } else if (this.datalength >= 18 && this.datalength < 25) {
              value.ticks.fontSize = 10;
            } else if (this.datalength >= 25) {
              value.ticks.fontSize = 8;
            }
          }
        }
      });

      this.barChartData = this.chartData.chartDataSet;
    }
  }
}
