import { Component, OnInit, Input } from '@angular/core';
import { SortEvent } from 'src/app/directives/sortable.directive';

@Component({
  selector: 'app-sort-indicator',
  templateUrl: './sort-indicator.component.html',
  styleUrls: ['./sort-indicator.component.scss'],
})
export class SortIndicatorComponent implements OnInit {
  @Input() sortEvent: SortEvent;
  @Input() column: string;

  constructor() {}

  ngOnInit() {}
}
