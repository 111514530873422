<span
  class="sort-indicator"
  [ngSwitch]="sortEvent?.direction"
  *ngIf="sortEvent?.column === column"
  [ngStyle]="{
    top: sortEvent?.direction === 'asc' ? '2px' : '-4px'
  }"
>
  <i class="fa fa-sort-up" *ngSwitchCase="'asc'"></i>
  <i class="fa fa-sort-down" *ngSwitchCase="'desc'"></i>
  <i class="fa" *ngSwitchDefault></i>
</span>
