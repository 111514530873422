<div *ngIf="chartData; else message">
    <canvas baseChart
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [legend]="false"
            [chartType]="lineChartType"
            [colors]="lineChartColors"
            height="160">
    </canvas>
    <ul *ngIf="lineChartData"
        class="legend d-flex justify-content-center">
        <li *ngFor="let legend of lineChartData; let i=index"
            class="mx-2">
            <span class="mr-1 mb-2"
                  *ngIf="i === 0"
                  [ngStyle]="{'border-color': legend.borderColor, 'border-bottom': 3+'px solid '+legend.borderColor}">
                <span class="mt-2 ml-2"
                      [ngStyle]="{'background-color': legend.backgroundColor, 'border-color': legend.borderColor, 'border': 3+'px solid '+legend.borderColor, 'width':'5px', 'height': '5px'}"></span>
            </span>
            <span class="mr-1 my-2"
                  *ngIf="i === 1"
                  [ngStyle]="{'height': 1+'px', 'background-color': legend.backgroundColor, 'border-color': legend.borderColor, 'border-bottom': 2+'px solid '+ legend.borderColor}"></span>
            <span class="mr-1 my-2"
                  *ngIf="i === 2"
                  [ngStyle]="{'height': 1+'px', 'background-color': white, 'border-color': legend.borderColor, 'border-bottom': 2+'px dashed '+ legend.borderColor}"></span>
            {{legend.label}}
        </li>
    </ul>
</div>
<ng-template #message>No data available</ng-template>