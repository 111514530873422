<div *ngIf="chartData; else message">
  <canvas baseChart
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [legend]="false"
          [chartType]="lineChartType"
          [colors]="lineChartColors"
          height="160">
  </canvas>
  <ul *ngIf="lineChartData"
      class="legend d-flex justify-content-center">
    <li *ngFor="let legend of lineChartData; let i=index"
        class="mx-2">
      <span class="m-1"
            *ngIf="i===0"
            [ngStyle]="{'background-color': legend.backgroundColor, 'border-color': legend.borderColor, 'border': 2+'px solid '+legend.borderColor}">
      </span>
      <span class="m-2"
            *ngIf="i>0"
            [ngStyle]="{'height': 1+'px', 'background-color': legend.backgroundColor, 'border-color': legend.borderColor, 'border-bottom': 2+'px solid '+ legend.borderColor}"></span>
      {{legend.label}}
    </li>
  </ul>
</div>
<ng-template #message>No data available</ng-template>