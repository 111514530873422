import { Component, Input } from '@angular/core';
import { AuthenticationService } from '@com-delta-dcflightreliability/dc-common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() title: string;
  public user$ = this.authService.user$;

  constructor(private authService: AuthenticationService) {}
}
