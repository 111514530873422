import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, LinearTickOptions } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color } from 'ng2-charts';
import * as _ from 'lodash';

import { CarrierChartService } from '../../services/carrier-chart.service';
import { IChart } from '../../models/chart.model';
import { NgbModalRef, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-station-chart-modal',
  templateUrl: './station-chart-modal.component.html',
  styleUrls: ['./station-chart-modal.component.scss']
})
export class StationChartModalComponent implements OnInit {
  public chartModalRef: NgbModalRef;
  public datalength = 0;
  public originalChartData;
  public chartParams;
  @Input() data: IChart;

  public chartData: IChart;
  public barChartLabels: string[];
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [pluginDataLabels];
  public barChartData: Chart.ChartDataSets[];
  public barChartColor: Color[];
  public tickOptions: LinearTickOptions = {
    beginAtZero: true,
    precision: 0
  };
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    title: {
      display: true,
      text: '',
      padding: 25
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          fontSize: 12,
          callback: (value) => {
            // Update xAxes lable string
            return this.carrierChartService.setCorrectiveActionLabel(value);
          }
        }
      }], yAxes: [{
        ticks: this.tickOptions,
        scaleLabel: {
          display: true,
          labelString: '',
          fontSize: 12
        },
        gridLines: {
          offsetGridLines: false
        }
      }],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        display: true,
        font: {
          size: 12
        }
      }
    }
  };
  constructor(private carrierChartService: CarrierChartService, private modalService: NgbModal, public modal: NgbActiveModal) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.chartData = this.data;
      // Update chart data
      this.barChartOptions.title.text = this.chartData.title;
      this.barChartLabels = this.chartData.chartLabel;
      this.barChartColor = this.chartData.chartColors;
      this.barChartData = this.chartData.chartDataSet;

      this.datalength = 0;
      _.forEach(this.chartData.chartDataSet, value => {
        // Update Bar formatting
        value.maxBarThickness = 50;
        value.barPercentage = 0.5;
        value.barThickness = 'flex';
        this.datalength = value.data.length;

        if (this.datalength > 10) {
          // Update bar label font size based on response data
          if (this.datalength > 10 && this.datalength < 25) {
            // tslint:disable-next-line: no-string-literal
            this.barChartOptions.plugins.datalabels.font['size'] = 10;
          } else if (this.datalength > 25) {
            // tslint:disable-next-line: no-string-literal
            this.barChartOptions.plugins.datalabels.font['size'] = 8;
          }
        }
      });

      // Iterate yAxes option array and set scale label
      _.forEach(this.barChartOptions.scales.yAxes, value => {
        if (value.scaleLabel) {
          value.scaleLabel.labelString = this.chartData.yaxisScaleLabel;
        }
      });

      // Iterate xAxes option array and set scale label
      _.forEach(this.barChartOptions.scales.xAxes, value => {
        if (this.datalength > 10) {
          if (value.ticks) {
            // Update xAxis label font size based on response data
            if (this.datalength > 10 && this.datalength < 18) {
              value.ticks.fontSize = 12;
            } else if (this.datalength >= 18 && this.datalength < 25) {
              value.ticks.fontSize = 10;
            } else if (this.datalength >= 25) {
              value.ticks.fontSize = 8;
            }
          }
        }
      });
    }
  }

}
